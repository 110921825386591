.nav-link, .nav-link:visited, .nav-link:active, .nav-link:hover, .nav-link:focus {
  color: white;
  text-decoration: none;
  margin-right: 15px;
}

.login-box {
  padding: 25px;
  background-color: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form {
  background-color: #ccc;
  border-radius: 10px;
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
}

.chat-form {
  background-color: #ccc;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 10px;
}

.chat-item {
  background-color: #ccc;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 10px;
}

.small-container {
  max-width: 500px;
  margin: 0 auto;
}